import React, { useState } from 'react';

import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import Loader from 'react-loader-spinner';

import { Typeahead } from 'react-bootstrap-typeahead';
import { Formik, Field, Form } from 'formik';
import { useQuery, useMutation } from '@apollo/client';

import UserList from '../UserList';

import USERS from '../../../queries/getUsers';
import ROLES from '../../../queries/getRoles';
import NEW_USER from '../../../mutations/createUser';

const UserForm = () => {
  const [show, setShow] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const { data: dataRoles, loading: loadRoles } = useQuery(ROLES);
  // eslint-disable-next-line no-unused-vars
  const { data: dataUsers, loading } = useQuery(USERS);

  // eslint-disable-next-line no-unused-vars
  const [newUser, { data: newDataUser, error }] = useMutation(NEW_USER, {
    update: (cache, result) => {
      const data = cache.readQuery({ query: USERS });

      cache.writeQuery({
        query: USERS,
        data: {
          getUsers: [result.data.createUser, ...data.getUsers],
        },
      });
    },
  });

  const handleViewUserModal = () => {
    setShow((prevState) => !prevState);
  };

  if (loading && loadRoles) {
    return (
      <Loader
        className="loader"
        type="Oval"
        color="black"
        height={75}
        width={75}
      />
    );
  }

  if (error) {
    return <p>{JSON.stringify(error)}</p>;
  }

  return (
    <>
      {dataUsers && dataUsers.getUsers ? (
        <UserList users={dataUsers.getUsers} />
      ) : (
        <Loader
          className="loader"
          type="Oval"
          color="black"
          height={75}
          width={75}
        />
      )}

      <Button variant="primary" onClick={handleViewUserModal}>
        User form
      </Button>

      <Modal show={show} onHide={handleViewUserModal}>
        <Modal.Header closeButton className="dark">
          <Modal.Title>Create new user</Modal.Title>
        </Modal.Header>
        <Modal.Body className="dark">
          <Formik
            initialValues={{
              username: '',
              password: '',
              firstName: '',
              lastName: '',
              email: '',
              role: '',
            }}
            onSubmit={(values, { setSubmitting }) => {
              newUser({
                variables: {
                  createUserInput2: {
                    username: values.username,
                    password: values.password,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    roleId: values.role.id,
                  },
                },
              });

              setSubmitting(false);

              handleViewUserModal();
            }}
          >
            {/* eslint-disable-next-line no-unused-vars */}
            {({ isSubmitting, setFieldValue, setFieldTouched, values }) => (
              <Form>
                <Field name="username">
                  {({ field }) => (
                    <FormGroup className="mb-3" controlId="username">
                      <FormLabel>Username:</FormLabel>
                      <FormControl
                        required
                        type="text"
                        placeholder="Enter username"
                        value={field.value}
                        onChange={field.onChange}
                      />
                    </FormGroup>
                  )}
                </Field>
                <Field name="password">
                  {({ field }) => (
                    <FormGroup className="mb-3" controlId="password">
                      <FormLabel>Password:</FormLabel>
                      <FormControl
                        required
                        type="password"
                        placeholder="Enter password"
                        value={field.value}
                        onChange={field.onChange}
                      />
                    </FormGroup>
                  )}
                </Field>
                <Field name="firstName">
                  {({ field }) => (
                    <FormGroup className="mb-3" controlId="firstName">
                      <FormLabel>First name:</FormLabel>
                      <FormControl
                        required
                        type="text"
                        placeholder="Enter first name"
                        value={field.value}
                        onChange={field.onChange}
                      />
                    </FormGroup>
                  )}
                </Field>
                <Field name="lastName">
                  {({ field }) => (
                    <FormGroup className="mb-3" controlId="lastName">
                      <FormLabel>Last name:</FormLabel>
                      <FormControl
                        required
                        type="text"
                        placeholder="Enter last name"
                        value={field.value}
                        onChange={field.onChange}
                      />
                    </FormGroup>
                  )}
                </Field>
                <Field name="email">
                  {({ field }) => (
                    <FormGroup className="mb-3" controlId="email">
                      <FormLabel>Email:</FormLabel>
                      <FormControl
                        required
                        type="date"
                        placeholder="Enter email"
                        value={field.value}
                        onChange={field.onChange}
                      />
                    </FormGroup>
                  )}
                </Field>
                <Field name="role">
                  {() => (
                    <FormGroup className="mb-3" controlId="role">
                      <FormLabel>Role:</FormLabel>
                      <Typeahead
                        required
                        id="role-select"
                        options={dataRoles.getRoles.map((element) => ({
                          id: element.id,
                          label: element.name,
                        }))}
                        placeholder="Select/Enter role"
                        onChange={(selected) => {
                          const value = selected.length > 0 ? selected : '';
                          setFieldValue('role', value);
                        }}
                        // eslint-disable-next-line no-unused-vars
                        onInputChange={(text, e) => {
                          setFieldValue('role', text);
                        }}
                        // eslint-disable-next-line no-unused-vars
                        onBlur={(e) => setFieldTouched('role', true)}
                      />
                    </FormGroup>
                  )}
                </Field>

                <Button type="submit" disabled={isSubmitting}>
                  {isSubmitting ? 'Please wait...' : 'Submit'}
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UserForm;
