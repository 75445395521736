import React from 'react';
import PropTypes from 'prop-types';

import { Switch, Route } from 'react-router-dom';

import Login from '../Login';
import Clients from '../Clients';
import StaffOps from '../StaffOps';
import Projects from '../Projects';
import Settings from '../Settings';
import PrivateRoute from '../PrivateRoute';
import StaffOpsRoute from '../StaffOpsRoute';

const Routes = ({ userRole }) => (
  <Switch>
    <Route exact path="/">
      <Login />
    </Route>
    <PrivateRoute exact path="/projects">
      <Projects userRole={userRole} />
    </PrivateRoute>
    <StaffOpsRoute exact path="/staffops">
      <StaffOps userRole={userRole} />
    </StaffOpsRoute>
    <StaffOpsRoute path="/clients">
      <Clients userRole={userRole} />
    </StaffOpsRoute>
    <Route path="/settings">
      <Settings />
    </Route>
  </Switch>
);

Routes.propTypes = {
  userRole: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Routes.defaultProps = {
  userRole: 0 || '0',
};

export default Routes;
