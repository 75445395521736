import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormCheck from 'react-bootstrap/FormCheck';
import Modal from 'react-bootstrap/Modal';

// eslint-disable-next-line no-unused-vars
import { Typeahead } from 'react-bootstrap-typeahead';
import { useQuery, useMutation } from '@apollo/client';
import { Formik, Field, Form } from 'formik';

import ROLES from '../../../queries/getRoles';
import UPDATE_USER from '../../../mutations/updateUser';

const ViewClient = ({ show, onHide, user }) => {
  /* eslint-disable no-unused-vars */
  const [updateUser, { data: updateUserData }] = useMutation(UPDATE_USER);
  const { data: dataRoles } = useQuery(ROLES);
  /* eslint-enable no-unused-vars */

  const [checked, setChecked] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const handleEditCheck = () => {
    setChecked((prevState) => !prevState);

    if (checked === true) {
      setDisabled(false);
    } else if (checked === false) {
      setDisabled(true);
    }
  };

  useEffect(() => {
    if (show === false) {
      setDisabled(false);
      setChecked(false);
    }
  }, [show]);

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton className="dark">
        <Modal.Title>User information</Modal.Title>
      </Modal.Header>
      <Modal.Body className="dark">
        <Formik
          initialValues={{
            username: '',
            password: '',
            firstName: '',
            lastName: '',
            email: '',
            role: '',
          }}
          onSubmit={(values, { setSubmitting }) => {
            updateUser({
              variables: {
                updateUserInput2: {
                  id: user.id,
                  username: values.username,
                  password: values.password,
                  firstName: values.firstName,
                  lastName: values.lastName,
                  email: values.email,
                  roleId:
                    values.role[0].id != null
                      ? values.role[0].id
                      : user.role.id,
                },
              },
            });

            setSubmitting(false);

            setDisabled(false);
            setChecked(false);

            onHide();
          }}
        >
          {/* eslint-disable-next-line no-unused-vars */}
          {({ isSubmitting, setFieldValue, setFieldTouched, values }) => (
            <Form>
              <Field name="username">
                {({ field }) => (
                  <FormGroup className="mb-3" controlId="username">
                    <FormLabel>Username:</FormLabel>
                    <FormControl
                      disabled={!disabled}
                      required
                      type="text"
                      placeholder="Enter username"
                      defaultValue={user.username}
                      onChange={field.onChange}
                    />
                  </FormGroup>
                )}
              </Field>
              <Field name="password">
                {({ field }) => (
                  <FormGroup className="mb-3" controlId="password">
                    <FormLabel>Password:</FormLabel>
                    <FormControl
                      required
                      disabled={!disabled}
                      type="password"
                      placeholder="Password hidden"
                      onChange={field.onChange}
                    />
                  </FormGroup>
                )}
              </Field>
              <Field name="firstName">
                {({ field }) => (
                  <FormGroup className="mb-3" controlId="firstName">
                    <FormLabel>First name:</FormLabel>
                    <FormControl
                      required
                      disabled={!disabled}
                      type="text"
                      placeholder="Enter first name"
                      defaultValue={user.firstName}
                      onChange={field.onChange}
                    />
                  </FormGroup>
                )}
              </Field>
              <Field name="lastName">
                {({ field }) => (
                  <FormGroup className="mb-3" controlId="lastName">
                    <FormLabel>Last name:</FormLabel>
                    <FormControl
                      required
                      disabled={!disabled}
                      type="text"
                      placeholder="Enter last name"
                      defaultValue={user.lastName}
                      onChange={field.onChange}
                    />
                  </FormGroup>
                )}
              </Field>
              <Field name="email">
                {({ field }) => (
                  <FormGroup className="mb-3" controlId="email">
                    <FormLabel>Email:</FormLabel>
                    <FormControl
                      required
                      disabled={!disabled}
                      type="email"
                      placeholder="Enter email"
                      defaultValue={user.email}
                      onChange={field.onChange}
                    />
                  </FormGroup>
                )}
              </Field>
              <Field name="role">
                {() => (
                  <FormGroup className="mb-3" controlId="role">
                    <FormLabel>Role:</FormLabel>
                    <Typeahead
                      required
                      disabled={!disabled}
                      id="role-select"
                      options={dataRoles.getRoles.map((element) => ({
                        id: element.id,
                        label: element.name,
                      }))}
                      defaultSelected={
                        user.role.name != null ? [user.role.name] : []
                      }
                      placeholder="Select/Enter role"
                      onChange={(selected) => {
                        const value = selected.length > 0 ? selected : '';
                        setFieldValue('role', value);
                      }}
                      // eslint-disable-next-line no-unused-vars
                      onInputChange={(text, e) => {
                        setFieldValue('role', text);
                      }}
                      // eslint-disable-next-line no-unused-vars
                      onBlur={(e) => setFieldTouched('role', true)}
                    />
                  </FormGroup>
                )}
              </Field>

              <FormCheck
                type="switch"
                id="custom-switch"
                label={<b>EDIT USER</b>}
                checked={checked}
                onChange={handleEditCheck}
              />

              <Button
                type="submit"
                variant="primary"
                disabled={!disabled || isSubmitting}
              >
                Submit changes
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

ViewClient.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    username: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    password: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
      PropTypes.arrayOf(PropTypes.string),
    ]),
  }).isRequired,
};

ViewClient.defaultProps = {
  show: false,
};

export default ViewClient;
