/* eslint-disable no-unused-vars */
import React from 'react';

import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import Button from 'react-bootstrap/Button';

import { useQuery, useMutation } from '@apollo/client';
import { Formik, Field, Form } from 'formik';

import jsonwebtoken from 'jsonwebtoken';

import USER from '../../../queries/getUser';
import UPDATE_USER_PASSWORD from '../../../mutations/updateUserPassword';
import CHECK_PASSWORD from '../../../mutations/checkPassword';

import './index.css';

const SettingsForm = () => {
  const accessToken = localStorage.getItem('accessToken');
  const decodedToken = jsonwebtoken.decode(accessToken);

  const [checkPass, { data: checkUserPass }] = useMutation(CHECK_PASSWORD);

  const [updateUserPass, { data: updateUserPassData }] =
    useMutation(UPDATE_USER_PASSWORD);

  const { data: dataUser, loading } = useQuery(USER, {
    variables: {
      getUserFilter3: {
        id: decodedToken.userId,
      },
    },
  });

  return (
    <div className="settings-form">
      <Formik
        initialValues={{
          oldPassword: '',
          password: '',
          confirmPassword: '',
        }}
        onSubmit={(values, { setSubmitting }) => {
          updateUserPass({
            variables: {
              updateUserInput2: {
                id: dataUser.getUser.id,
                password: values.confirmPassword,
              },
            },
          });

          setSubmitting(false);
        }}
      >
        {({ values, errors, isSubmitting }) => (
          <Form
            style={{
              top: '50%',
              left: '50%',
              width: '70%',
              position: 'absolute',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <Field name="oldPassword">
              {({ field }) => (
                <FormGroup className="mb-3" controlId="oldPassword">
                  <FormLabel>Old password:</FormLabel>
                  <FormControl
                    required
                    type="password"
                    placeholder="Enter old password"
                    onChange={field.onChange}
                    validate={checkPass({
                      variables: {
                        checkPasswordInput2: {
                          password: values.oldPassword,
                        },
                      },
                    })}
                  />
                </FormGroup>
              )}
            </Field>
            {(checkUserPass && checkUserPass.checkPassword) ||
            values.oldPassword === '' ? null : (
              <p
                style={{
                  color: 'red',
                  marginTop: '-10px',
                  marginLeft: '5px',
                }}
              >
                Incorrect password
              </p>
            )}

            <hr />

            <Field name="password">
              {({ field }) => (
                <FormGroup className="mb-3" controlId="password">
                  <FormLabel>New password:</FormLabel>
                  <FormControl
                    required
                    type="password"
                    placeholder="Enter new password"
                    onChange={field.onChange}
                  />
                </FormGroup>
              )}
            </Field>

            <Field name="confirmPassword">
              {({ field }) => (
                <FormGroup className="mb-3" controlId="confirmPassword">
                  <FormLabel>Confirm password:</FormLabel>
                  <FormControl
                    required
                    type="password"
                    placeholder="Confirm new password"
                    onChange={field.onChange}
                  />
                </FormGroup>
              )}
            </Field>

            {/* eslint-disable-next-line no-nested-ternary */}
            {values.password !== '' && values.confirmPassword !== '' ? (
              values.confirmPassword === values.password ? null : (
                <p
                  style={{
                    color: 'red',
                    marginTop: '-10px',
                    marginLeft: '5px',
                  }}
                >
                  Passwords do not match.
                </p>
              )
            ) : null}

            <Button
              disabled={
                !(
                  ((checkUserPass && checkUserPass.checkPassword) ||
                    values.oldPassword === '') &&
                  values.password !== '' &&
                  values.confirmPassword !== '' &&
                  values.confirmPassword === values.password
                )
              }
              type="submit"
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SettingsForm;
