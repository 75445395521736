/* eslint-disable no-unused-vars */
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import jsonwebtoken from 'jsonwebtoken';

import REFRESH_TOKENS from '../mutations/refreshTokens';

const httpLink = createHttpLink({
  uri: 'https://api.psa.iolap.academy/',
});

const getNewToken = async () => {
  // eslint-disable-next-line no-use-before-define
  const response = await client.mutate({
    mutation: REFRESH_TOKENS,
    variables: {
      getNewTokensInput: {
        refreshToken: localStorage.getItem('refreshToken'),
      },
    },
  });

  const newAccessToken = response.data.getNewTokens.accessToken;
  const newRefreshToken = response.data.getNewTokens.refreshToken;

  return { newAccessToken, newRefreshToken };
};

const authLink = setContext(async (request, { headers }) => {
  let accessToken = localStorage.getItem('accessToken');

  if (!accessToken) {
    return { headers };
  }

  try {
    const decodedToken = jsonwebtoken.decode(accessToken);

    if (Date.now() >= decodedToken.exp * 1000) {
      if (request.operationName !== 'GetNewTokensMutation') {
        const tokens = await getNewToken();

        localStorage.setItem('accessToken', tokens.newAccessToken);
        localStorage.setItem('refreshToken', tokens.newRefreshToken);

        accessToken = tokens.newAccessToken;

        return {
          headers: {
            ...headers,
            ...(accessToken && { authorization: `Bearer ${accessToken}` }),
          },
        };
      }
      return { headers };
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  return { headers };
});

const client = new ApolloClient({
  link: from([authLink, httpLink]),
  cache: new InMemoryCache(),
});

export default client;
